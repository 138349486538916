import hasFlexboxSupport from 'modernizr-esm/feature/css/flexbox';

function getChromeVersion() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
}

window.addEventListener('load', () => {
  // Bootstrap 4 does not support "Proxy Browsers" and calls out specifically the 3 below.
  // Redirect to info page when we don't support the browser or a specific feature that is critical for this app
  const isAmazonSilk = /(?:; ([^;)]+) Build\/.*)?\bSilk\/([0-9._-]+)\b(.*\bMobile Safari\b)?/.exec(
    navigator.userAgent
  );
  const isUCBrowser = navigator.userAgent.indexOf(' UCBrowser/') >= 0;
  const isOperaMini = navigator.userAgent.indexOf('Opera Mini') >= 0;
  // must test for IE 10 and below differently than IE 11
  const isIE10orBelow =
    // eslint-disable-next-line no-new-func
    document.querySelector('html').classList.contains('ie') ||
    Function('/*@cc_on return document.documentMode===10@*/')();
  const isAtLeastIE11 = !!(
    navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/)
  );

  // January 31, 2019
  // https://chromereleases.googleblog.com/2019/01/chrome-for-android-update_29.html
  // https://chromereleases.googleblog.com/2019/01/dev-channel-update-for-desktop_31.html
  const oldestSupportedChromeVersion = 72;
  const chromeVersion = getChromeVersion();

  const isChromeOldVersion = chromeVersion && Boolean(
    chromeVersion < oldestSupportedChromeVersion
  );

  const isUnsupportedBrowserPage =
    window.location.href.indexOf(window.SUPPORTED_BROWSERS_PATH) > -1;

  if (
    !isUnsupportedBrowserPage &&
    (isIE10orBelow ||
      isAtLeastIE11 ||
      isAmazonSilk ||
      isUCBrowser ||
      isOperaMini ||
      isChromeOldVersion ||
      // START: feature detection
      !hasFlexboxSupport)
  ) {
    // redirect user
    window.location.href = `${window.SUPPORTED_BROWSERS_PATH}?isUnsupportedBrowser=true`;
  }
});
